import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Maybe, Query, SanityStaffConnection } from "@graphql-types";
import SEO from "@shared/seo";
import { LogoType, PageContext } from "@util/types";
import { Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";
import { Container, PageWidth, Section } from "@util/standard";
import { colorsRGB, MOBILE_BREAKPOINT } from "@util/constants";
import { BlocksContent, Image, Link } from "@global";
import { GridContainer, ImageWrapper } from "./serviceProvidersTemplate";
import { useHandleQuery } from "@api";

interface DataQuery extends Query {
  proStaff: Maybe<SanityStaffConnection> | undefined;
  goStaff: Maybe<SanityStaffConnection> | undefined;
}

interface Props extends PageProps {
  data: DataQuery;
  pageContext: PageContext;
}

interface StaffSectionProps {
  staff: SanityStaffConnection;
  title: string;
}

const SectionStyled = styled(Section)`
  & + & {
    padding-top: 0;
  }
`;

const TitleWrapper = styled.div`
  padding-bottom: 40px;
  margin-bottom: 50px;
  border-bottom: 1.5px solid ${colorsRGB.main(0.2)};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
`;

const ContentWrapper = styled.div`
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1.5px solid ${colorsRGB.main(0.2)};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

export const ContactDetails = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const StaffSection = ({ staff, title }: StaffSectionProps) => {
  if (!staff?.nodes || staff.nodes.length < 1) return null;

  const handles = useHandleQuery();

  return (
    <SectionStyled>
      <PageWidth>
        <TitleWrapper>
          <h2 className="h3">{title}</h2>
        </TitleWrapper>
        <GridContainer>
          {staff.nodes.map(item => {
            if (!item || !item.slug) return;
            const { name, image, position, blockContent, packageCategory, slug } = item;

            return (
              <Container key={`team-${item.slug.current}`} flexDirection="column">
                <Link internalSlug={`/${handles.team?.pageMeta?.slug?.current}/${slug.current}`}>
                  {image &&
                    <ImageWrapper>
                      <Image data={image} isBackground />
                    </ImageWrapper>
                  }
                  <ContentWrapper>
                    <p>{name}</p>
                    <p>{position}</p>
                  </ContentWrapper>
                  <ContactDetails>
                    <BlocksContent data={blockContent} type={packageCategory as LogoType} margin="0 0 25px" />
                  </ContactDetails>
                </Link>
              </Container>
            );
          })}
        </GridContainer>

      </PageWidth>
    </SectionStyled>
  );
}

export default function TeamLandingTemplate({ data, pageContext }: Props) {

  if (!data?.proStaff || !data?.goStaff || !data?.sanityLockedPage) return null;
  const { proStaff, goStaff, sanityLockedPage: { pageMeta } } = data;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} />
      {proStaff &&
        <StaffSection staff={proStaff} title="HomeSell Pro" />
      }
      {goStaff &&
        <StaffSection staff={goStaff} title="HomeSell Go" />
      }
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query TeamLandingQuery($pageId: String) {
    sanityLockedPage(_id: { regex: $pageId }) {
      pageMeta {
        ...sanityPageMeta
      }
    }
    proStaff: allSanityStaff(
      sort: {order: DESC, fields: _updatedAt}
      filter: {packageCategory: {eq: "pro"}}
    ) {
      nodes {
        name
        position
        packageCategory
        slug {
          current
        }
        blockContent {
          ...sanityBlockContent
        }
        bio {
          ...sanityBlockContent
        }
        image {
          ...sanityImageFullWidth
        }
      }
    }
    goStaff: allSanityStaff(
      sort: {order: DESC, fields: _updatedAt}
      filter: {packageCategory: {eq: "go"}}
    ) {
      nodes {
        name
        position
        packageCategory
        slug {
          current
        }
        blockContent {
          ...sanityBlockContent
        }
        bio {
          ...sanityBlockContent
        }
        image {
          ...sanityImageFullWidth
        }
      }
    }
  }
`;
